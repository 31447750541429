<template>
  <div class="p-6">
    <div class="vx-row mb-4">
      <div class="w-1/2">
        <vs-input v-model.trim="name" class="w-full" label="Nombre" />
      </div>
      <div class="w-1/2">
        <vs-input
          v-model.number="downpaymentRate"
          type="number"
          class="w-full"
          name="downpayment_rate"
          :label="
            `Porcentaje (%) de ${$tc(
              'message.downpayment',
              2,
              user.countryCode
            )}`
          "
        />
      </div>
    </div>
    <div class="vx-row mb-4">
        <div class="w-full"  style="font-size:smaller; padding-left: 3px;">
          <div class="w-2/3">
            <vs-col vs-lg=4 style="padding-top: 7px;" >
              Tipo de {{ $tc("message.reservation", 2, user.countryCode) }}
            </vs-col>
            <vs-col vs-lg=8>
              <v-radio-group v-model="isReservationPercent" row
              hide-details style="margin-top: 0px;">
                  <v-radio
                    name="amount-percent"
                    :value="false"
                    label="Monto"
                    ></v-radio>
                    <v-radio
                    name="is_reservation_percent"
                    :value="true"
                    label="Porcentaje"></v-radio>
              </v-radio-group>
          </vs-col>
          </div>
      </div>
    </div>
    <div class="vx-row mb-4">
      <div class="w-1/2">
        <vs-input
          v-model.number="reservation"
          type="number"
          class="w-full"
          :label="`Monto de ${$tc('message.reservation', 2, user.countryCode)}`"
          name="downpayment_amount"
        />
      </div>
      <div class="w-1/2">
        <vs-input
          v-model.number="maxDownpaymentInstallments"
          type="number"
          class="w-full"
          label="Meses a pagar"
          name="downpayment_monthly"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="w-1/2">
        <vs-input
          v-model.number="pcvRate"
          type="number"
          class="w-full"
          label="Porcantaje (%) a pagar en PCV"
          name="downpayment_pcv_rate"
        />
      </div>
      <div class="w-1/2">
        <vs-input
          v-model.number="pcvDays"
          type="number"
          class="w-full"
          label="Día para pago de PCV"
          name="downpayment_pcv_days"
        />
      </div>
    </div>
    <div class="mt-2 toggle-dn center con-switch pb-4">
      Activo
      <vs-switch id="toggle-dn" v-model="active" class="toggle" />
    </div>
    <div class="vx-row mb-4">
      <vs-button
        icon-pack="feather"
        color="success"
        icon="icon-save"
        class="mt-8 m-2 w-full"
        @click="updateDownpayment"
        :disabled="fieldsReady"
        >Actualizar {{ name }}</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api as projectApi } from "../services";

export default {
  props: ["downpaymentType", "onUpdate"],
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.name ||
        !this.downpaymentRate ||
        !this.maxDownpaymentInstallments ||
        !this.reservation
      )
        return true;

      return false;
    }
  },
  watch: {
    downpaymentType(value) {
      this.downpaymentTypeId = value.id;
      this.name = value.name;
      this.downpaymentRate = value.downpayment_rate;
      this.pcvRate = value.pcv_rate;
      this.pcvDays = value.pcv_payment_day;
      this.maxDownpaymentInstallments = value.max_payment_installments;
      this.reservation = value.reservation;
      this.active = value.active;
      this.isReservationPercent = value.is_reservation_percent;
    }
  },
  data() {
    return {
      downpaymentTypeId: null,
      minDownpaymentPercent: 0,
      name: "",
      downpaymentRate: 0,
      reservation: 0,
      pcvRate: 0,
      pcvDays: 0,
      maxDownpaymentInstallments: 0,
      active: true,
      isReservationPercent: false
    };
  },
  methods: {
    async updateDownpayment() {
      const downpaymentType = await projectApi.updDownpaymentType(
        this.downpaymentTypeId,
        {
          name: this.name,
          downpayment_rate: this.downpaymentRate,
          reservation: this.reservation,
          pcv_rate: !this.pcvRate ? 0 : this.pcvRate,
          max_payment_installments: this.maxDownpaymentInstallments,
          pcv_payment_day: !this.pcvDays ? 0 : this.pcvDays,
          active: this.active,
          is_reservation_percent: this.isReservationPercent
        }
      );

      this.onUpdate(downpaymentType);
    }
  }
};
</script>

<style scoped>
#toggle-dn {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.v-label {
    font-size: 12px;
}
</style>