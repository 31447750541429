<template>
  <div>
    <div v-if="!taxes.length">
      <vs-alert :active.sync="taxesDisabled" color="primary" icon="information">
        <span>Impuestos no aplican para el país seleccionado en este proyecto.</span>
      </vs-alert>
    </div>
    <div v-if="!taxesDisabled">
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Formulario de Impuestos:</h6>
              <vs-divider></vs-divider>
              <div class="vx-row mb-4">
                <div class="vx-col w-full">
                  <label for="tax">Impuesto</label>
                  <v-select
                    v-model="tax"
                    @input="updateProportion"
                    :options="this.taxes"
                  >
                  </v-select>
                </div>
                <br />

                <div class="vx-col w-full">
                  <vs-input
                    v-validate="'required'"
                    type="number"
                    class="mt-3 w-full"
                    label="Porcentaje de impuesto (%)"
                    name="percentage"
                    v-model.number="rate"
                    :disabled="true"
                  />
                </div>

                <div class="vx-col w-full">
                  <vs-input
                    v-validate="'required'"
                    type="number"
                    class="mt-3 w-full"
                    label="Proporción de Impuesto (%)"
                    name="proportion"
                    v-model.number="proportion"
                  />
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    @click="addProjectTax()"
                    v-if="tax != '' && proportion != ''"
                    color="success"
                    icon-pack="feather"
                    icon="icon-upload"
                    class="mr-3 mb-2"
                    >Agregar Impuesto</vs-button
                  >
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Impuestos asignados:</h6>
              <vs-divider></vs-divider>
              <vs-alert
                :active.sync="showTaxesAlert"
                color="danger"
                icon="information"
              >
                <span>No es posible eliminar. Impuestos son requeridos.</span>
              </vs-alert>
              <vs-list>
                <vs-list-item
                  v-for="t in projectTaxes"
                  :key="t.id"
                  :title="`${t.tax.name} ${t.tax.rate}%`"
                  :subtitle="`Proporción: ${t.proportion}%`"
                >
                  <vs-button
                    @click="openDeletePopUp(t)"
                    color="danger"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-trash"
                  ></vs-button>
                </vs-list-item>
              </vs-list>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-popup title="Eliminación" :active.sync="popupTaxDelete">
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <p>
              ¿Desea eliminar <b>{{ TaxNameToDelete }}</b> de los impuestos del
              proyecto?
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              @click="removeTax(TaxTypeToDelete)"
              class="mt-4"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { taxApi, api } from "../services";

export default {
  data() {
    return {
      taxes: [],
      projectTaxes: [],
      tax: "",
      proportion: 0,
      rate: 0,
      popupTaxDelete: false,
      TaxTypeToDelete: "",
      TaxNameToDelete: "",
      showTaxesAlert: false,
      taxesDisabled: true
    };
  },
  components: {
    "v-select": vSelect
  },
  async mounted() {
    await this.fetchTaxes();
    await this.fetchProjectTaxes();
  },
  methods: {
    async fetchTaxes() {
      const projectId = this.$store.state.project.currentProjectId;
      const project = await api.get(projectId);
      const countryId = project.city.country.id;
      const taxes = await taxApi.countryTaxes(countryId);
      if (taxes.length) this.taxesDisabled = false;

      this.taxes = taxes.map(t => {
        return {
          value: t.id,
          label: t.name,
          type: t.type,
          proportion: t.proportion,
          rate: t.rate
        };
      });
    },
    async fetchProjectTaxes() {
      this.projectTaxes = await taxApi.list(
        this.$store.state.project.currentProjectId
      );
    },
    async updProjectTax(projectTaxId) {
      await api.updProjectTax(projectTaxId, {
        proportion: this.proportion
      });
      this.$vs.notify({
        time: 6000,
        title: `¡Se ha actualizado el Impuesto!`,
        text: `Impuesto actualizado correctamente.`,
        color: "success"
      });
      await this.fetchProjectTaxes();
      this.proportion = "";
      this.rate = "";
      this.tax = "";
      this.showTaxesAlert = false;
    },
    async addProjectTax() {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        const taxId = this.tax.value;
        const taxAssigned = this.projectTaxes.find(i => i.tax.id === taxId);

        if (taxAssigned) {
          await this.updProjectTax(taxAssigned.id);
          return;
        }
        const projectTax = [
          {
            tax_id: this.tax.value,
            project_id: projectId,
            proportion: this.proportion
          }
        ];

        await api.addProjectTaxes(projectTax);
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha creado el Impuesto!`,
          text: `Impuesto ingresado correctamente.`,
          color: "success"
        });
        await this.fetchProjectTaxes();
        this.proportion = "";
        this.rate = "";
        this.tax = "";
        this.showTaxesAlert = false;
      } catch (e) {
        this.$vs.notify({
          time: 6000,
          title: `Error guardando el impuesto del proyecto`,
          text: `No se ha podido guardar los impuestos`,
          color: "danger"
        });
      }
    },
    async removeTax(projectTaxId) {
      try {
        const lastTaxes = this.projectTaxes.filter(
          tax => tax.id != projectTaxId
        );
        if (!lastTaxes.length) {
          this.popupTaxDelete = false;
          this.showTaxesAlert = true;
          return;
        }
        await api.delProjectTax(projectTaxId);
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha eliminado el Impuesto!`,
          text: `Impuesto eliminado correctamente.`,
          color: "success"
        });
        await this.fetchProjectTaxes();
        this.TaxTypeToDelete = "";
        this.TaxNameToDelete = "";
        this.popupTaxDelete = false;
      } catch (e) {
        this.$vs.notify({
          title: `Error al eliminar el adicional`,
          text: `No se ha podido eliminar el adicional del proyecto`,
          color: "danger"
        });
      }
    },
    updateProportion(tax) {
      this.rate = tax.rate;
      this.proportion = tax.proportion;
    },
    openDeletePopUp(projectTax) {
      this.popupTaxDelete = true;
      this.TaxTypeToDelete = projectTax.id;
      this.TaxNameToDelete = projectTax.tax.name;
    }
  }
};
</script>
