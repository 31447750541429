<template>
  <div>
    <vx-card>
      <vs-row vs-w="12">
        <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
          <h6>Expoinmobiliarias</h6>
          <br />

          <div v-if="!expoModule">
            <vs-alert color="primary" icon="information">
              <span>Próximamente ...</span>
            </vs-alert>
          </div>
          <div v-else>
            <div v-if="expoConnected">
              <vs-row>
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full mb-3">
                    <vs-input
                      size="large"
                      icon-pack="feather"
                      class="w-full"
                      icon="icon-globe"
                      label="ID de expoinmobiliaria"
                      color="primary"
                      required="true"
                      name="expo_id"
                      v-model.trim="expoId"
                      :disabled="true"
                    />
                  </div>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full mb-3">
                    <vs-input
                      size="large"
                      icon-pack="feather"
                      class="w-full"
                      icon="icon-globe"
                      label="Número de stand"
                      color="primary"
                      required="true"
                      name="expo_id"
                      v-model.trim="expoStandNumber"
                    />
                  </div>
                  <vs-button
                    v-if="
                      String(
                        getAttribute(
                          project.project_setting_values,
                          'EXPO_STAND_NUMBER'
                        )
                          ? getAttribute(
                              project.project_setting_values,
                              'EXPO_STAND_NUMBER'
                            ).value
                          : ''
                      ) != expoStandNumber
                    "
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    @click="
                      editProjectSetting(
                        expoStandNumberAttrId,
                        'EXPO_STAND_NUMBER',
                        expoStandNumber,
                        'número de stand'
                      )
                    "
                  ></vs-button>
                </vs-col>
              </vs-row>
              <div class="ml-4 center flex" v-if="expoProjectFeaturedLimit">
                <vs-switch v-model="expoFeatured" class="mt-2 toggle" />
                <p class="mt-2 ml-3">activar este proyecto como destacado</p>
              </div>
              <p
                v-if="expoProjectFeaturedLimitError"
                style="color: #ff3d5d"
                class="mt-2"
              >
                Has alcanzado el límite de
                {{ expoProjectFeaturedLimit }} proyectos destacados para esta
                expoinmobiliaria.
              </p>
            </div>
            <div v-else>
              <vs-row>
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full mb-3">
                    <vs-input
                      size="large"
                      icon-pack="feather"
                      class="w-full"
                      icon="icon-globe"
                      label="ID de expoinmobiliaria"
                      color="primary"
                      required="true"
                      name="expo_id"
                      v-model.trim="expoId"
                    />
                  </div>
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    :disabled="!expoId"
                    @click="connectExpo"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <p v-if="expoProjectLimitError" style="color: #FF3D5D; font-">
                Has alcanzado el límite de {{ expoProjectLimit }} proyectos para
                esta expoinmobiliaria.
              </p>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card class="mt-4">
      <vs-row vs-w="12">
        <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
          <h6>Otras configuraciones</h6>
          <br />
          <div class="flex">
            <vs-button
              color="danger"
              icon-pack="feather"
              icon="icon-save"
              type="filled"
              ref="loadableButton"
              id="button-with-loading"
              class="vs-con-loading__container w-full"
              @click="openPopup"
              >Eliminar proyecto</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vs-popup title="Eliminación de proyecto" :active.sync="deletePopup">
      <h5>¿Deseas eliminar {{ project.name }}?</h5>
      <vs-button
        color="danger"
        icon-pack="feather"
        icon="icon-save"
        type="filled"
        ref="loadableButton"
        id="button-with-loading"
        class="vs-con-loading__container w-full mt-4"
        @click="deleteProject"
        >Si, eliminar proyecto</vs-button
      >
    </vs-popup>
  </div>
</template>

<script>
import projectApi from "../services/project.api";
import { sentryCaptureException } from "../../../helpers/Sentry";

const defaultExpoId = process.env.VUE_APP_EXPO_ID || "zcNWdws6lhyyDXUdYV8Y";

export default {
  async mounted() {
    await this.getProject();
  },
  data() {
    return {
      project: null,
      deletePopup: false,
      expoModule: false,
      expoConnected: false,
      expoId: "",
      expoIdAttr: null,
      expoStandNumber: "",
      expoStandNumberAttrId: null,
      expoFeatured: false,
      expoFeaturedAttrId: null,
      expoDefaultFeatured: false,
      expoProjectLimit: 0,
      expoProjectFeaturedLimit: 0,
      expoProjectLimitError: false,
      expoProjectFeaturedLimitError: false,
    };
  },
  watch: {
    async expoFeatured(value) {
      if (value != this.expoDefaultFeatured) {
        this.featuredProject(value);
      }
    },
  },
  methods: {
    async getProject() {
      const projectId = this.$store.state.project.currentProjectId;
      this.project = await projectApi.get(projectId);

      const defaultExpoId = this.getAttribute(
        this.project.project_setting_values,
        "EXPO_ID"
      );
      const defaultExpoStandNumber = this.getAttribute(
        this.project.project_setting_values,
        "EXPO_STAND_NUMBER"
      );
      const defaultExpoFeatured = this.getAttribute(
        this.project.project_setting_values,
        "FEATURED_DEVELOPER_PROJECT"
      );

      this.expoModule =
        this.getDeveloperAttribute(
          this.project.property_developer.property_developer_settings,
          "EXPO_ID"
        ) || false;

      const expoProjectLimit =
        this.getDeveloperAttribute(
          this.project.property_developer.property_developer_settings,
          "EXPO_PROJECT_LIMIT"
        ) || 0;
      if (expoProjectLimit)
        this.expoProjectLimit = parseInt(expoProjectLimit.value || 0);

      const expoProjectFeaturedLimit =
        this.getDeveloperAttribute(
          this.project.property_developer.property_developer_settings,
          "EXPO_PROJECT_FEATURED_LIMIT"
        ) || 0;

      if (expoProjectFeaturedLimit)
        this.expoProjectFeaturedLimit = parseInt(
          expoProjectFeaturedLimit.value || 0
        );

      console.log(this.expoProjectLimit, this.expoProjectFeaturedLimit);

      this.expoId = defaultExpoId ? defaultExpoId.value : "";
      this.expoIdAttr = defaultExpoId ? defaultExpoId.id : null;

      this.expoStandNumber = defaultExpoStandNumber
        ? defaultExpoStandNumber.value
        : "";
      this.expoStandNumberAttrId = defaultExpoStandNumber
        ? defaultExpoStandNumber.id
        : null;

      this.expoFeatured = defaultExpoFeatured
        ? defaultExpoFeatured.value == "1"
          ? true
          : false
        : false;
      this.expoFeaturedAttrId = defaultExpoFeatured
        ? defaultExpoFeatured.id
        : null;
      this.expoDefaultFeatured = defaultExpoFeatured
        ? defaultExpoFeatured.value == "1"
          ? true
          : false
        : false;

      if (this.expoId) this.expoConnected = true;
    },
    openPopup() {
      this.deletePopup = true;
    },
    async deleteProject() {
      try {
        this.deletePopup = false;

        await projectApi.deleteById(this.project.id);

        this.$vs.notify({
          time: 6000,
          title: `Project ${this.project.name} 🏢🚀.`,
          text: `Eliminado correctamente.`,
          color: "success",
        });

        this.$router.push("proyectos");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible eliminar proyecto`,
          text: `No ha sido posible eliminar proyecto`,
          color: "danger",
        });
      }
    },
    getAttribute(attributes, key) {
      return attributes.find((attr) => attr.project_setting === key) || null;
    },
    getDeveloperAttribute(attributes, key) {
      return (
        attributes.find(
          (attr) => attr.property_developer_setting_value_id === key
        ) || null
      );
    },
    async connectExpo() {
      this.expoProjectLimitError = false;
      if (this.expoId !== defaultExpoId) {
        this.$vs.notify({
          title: `Expo Id no encontrado`,
          text: `Por favor verifica tu id de acceso`,
          color: "danger",
        });
        return;
      }

      const { projectExpoCount } = await projectApi.getExpoValues(
        this.project.property_developer.id
      );

      if (projectExpoCount + 1 > this.expoProjectLimit) {
        this.expoProjectLimitError = true;
        return;
      }

      this.editProjectSetting(
        this.expoIdAttr,
        "EXPO_ID",
        this.expoId,
        " expo id"
      );
    },
    async featuredProject(enabled) {
      try {
        const { projectFeatureCount } = await projectApi.getExpoValues(
          this.project.property_developer.id
        );

        if (enabled && !this.expoFeaturedAttrId) {
          if (projectFeatureCount + 1 > this.expoProjectFeaturedLimit) {
            this.expoProjectFeaturedLimitError = true;
            this.expoFeatured = false;
            return;
          }
          const newAttr = await projectApi.addProjectSetting(
            this.project.id,
            "1",
            "FEATURED_DEVELOPER_PROJECT"
          );

          this.expoDefaultFeatured = true;
          this.expoFeaturedAttrId = newAttr.id;
        } else {
          await projectApi.delSetting(this.expoFeaturedAttrId);
          this.expoDefaultFeatured = false;
          this.expoFeaturedAttrId = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editProjectSetting(settingId, settingKey, value, text) {
      try {
        const findAttributes = this.getAttribute(
          this.project.project_setting_values,
          settingKey
        );

        if (!findAttributes) {
          const newAttr = await projectApi.addProjectSetting(
            this.project.id,
            String(value),
            settingKey
          );
          if (settingKey === "EXPO_ID") this.expoIdAttr = newAttr.id;
          if (settingKey === "EXPO_STAND_NUMBER")
            this.expoStandNumberAttrId = newAttr.id;
          if (settingKey === "FEATURED_DEVELOPER_PROJECT") {
            this.expoDefaultFeatured = this.expoFeatured ? true : false;
            this.expoFeaturedAttrId = newAttr.id;
          }
        } else {
          await projectApi.updProjectSettingById(settingId, {
            value: String(value),
          });
        }

        await this.getProject();
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `Fue editado correctamente.`,
          color: "success",
        });
      } catch (e) {
        console.log(e);
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `no ha podido ser editado debido a un error en el sistema.`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style>
.toggle {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
