<template>
  <div>
    <div>
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Días de validez</h6>
              <vs-divider></vs-divider>
              <vs-row class="p-1">
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full ">
                    <vs-input
                      size="large"
                      icon-pack="feather"
                      class="w-full"
                      icon="icon-calendar"
                      label="Días de validez de cotización"
                      color="primary"
                      type="number"
                      v-validate="'required'"
                      name="quote_max_days"
                      v-model.number="maxDaysQuote"
                    />
                  </div>
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    v-if="
                      maxDaysQuote > 0 &&
                        maxDaysQuote !== project.max_days_quote
                    "
                    @click.native="updateMaxDays"
                  ></vs-button>
                </vs-col>
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full ">
                    <vs-input
                      size="large"
                      icon-pack="feather"
                      class="w-full"
                      icon="icon-calendar"
                      :label="
                        `Días de validez de ${$tc(
                          'message.optioned',
                          2,
                          user.countryCode
                        )}`
                      "
                      color="primary"
                      type="number"
                      v-validate="'required'"
                      name="quote_optioned_days"
                      v-model.number="maxDaysOptioned"
                    />
                  </div>
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    v-if="
                      maxDaysOptioned > 0 &&
                        maxDaysOptioned !== project.max_days_optioned
                    "
                    @click.native="updateMaxDaysOptioned"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br />

              <h6>
                Días de pago
                {{ $tc("message.reservation", 1, user.countryCode) }}
              </h6>
              <vs-divider></vs-divider>
              <vs-row class="p-1">
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <vs-input
                    size="large"
                    icon-pack="feather"
                    class="w-full"
                    icon="icon-calendar"
                    color="primary"
                    type="number"
                    v-validate="'required'"
                    name="quote_max_payments"
                    v-model.number="paymentDay"
                    label="Ingresa el día de pago"
                  />
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="m-2 mt-6"
                    @click.native="addPaymentDayReservation"
                  ></vs-button>
                </vs-col>
                <vs-col class="sm:w-full lg:w-1/2 px-2">
                  <span for="" class="vs-input--label">Días actuales</span>
                  <vs-table :data="paymentDaysReservation" class="w-full">
                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr]">
                          {{ data[indextr] }} de cada mes
                        </vs-td>
                        <vs-td :data="data[indextr]">
                          <vs-button
                            color="danger"
                            type="border"
                            icon="delete"
                            @click="removePaymentDays(data[indextr])"
                          >
                          </vs-button>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-col>
              </vs-row>
              <br />
              <h6>Permisos para Vendedores:</h6>
              <vs-divider></vs-divider>
              <vs-row>
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <vs-switch
                    v-model="allowOptioned"
                    :label="`Permitir ${$tc(
                      'message.optioned',
                      2,
                      user.countryCode
                    )}`"
                  />
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    v-if="allowOptioned !== project.allow_optioned"
                    @click="updateAllowOptioned"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <br />
              <h6>Plantilla de Cotización:</h6>
              <vs-divider></vs-divider>
              <vs-row class="p-1">
                <vs-col class="flex sm:w-full lg:w-1/2 px-2">
                  <div class="w-full sm:w-full ">
                    <v-select
                      v-model="template"
                      :options="this.templates"
                    ></v-select>
                  </div>
                  <vs-button
                    icon-pack="feather"
                    color="success"
                    icon="icon-save"
                    class="mt-8 m-2"
                    v-if="template != project.quote_template"
                    @click="updateTemplate()"
                  ></vs-button>
                </vs-col>
              </vs-row>

              <vs-row class="mt-8">
                <vs-col class="lg:w-full">
                  <h6 class="ml-4 mb-1">Terminos y condiciones:</h6>
                  <div class="flex w-full mb-4 px-2">
                    <div class="w-full m-1">
                      <vs-textarea
                        v-model="termsAndConditions"
                        height="200px"
                      />
                    </div>
                    <div class="p-2 flex">
                      <vs-button
                        class="mt-6 vs-con-loading__container"
                        id="DescriptionUpdate"
                        color="success"
                        type="filled"
                        icon-pack="fas"
                        icon="fa-save"
                        @click.native="updateTermAndConditions"
                      ></vs-button>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <vx-card>
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-full md:w-1/2 mt-base">
              <img
                class="LogoProyectQuote"
                :src="this.getImageCover(project.project_images)"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 text-right">
              <div class="invoice__invoice-detail mt-6">
                <h6>Cotización No.</h6>
                <p>#FAS828895</p>

                <h6 class="mt-4">Fecha de solicitud:</h6>
                <p>1/1/2020</p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-12">
              <h5>Att.</h5>
              <div class="invoice__recipient-info my-2">
                <h6>Emilio Quezada</h6>
              </div>
              <div class="invoice__recipient-contact">
                <p class="flex items-center">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">55587589</span>
                </p>
                <p class="flex items-center">
                  <feather-icon
                    icon="MailIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">equza@gmailito.com</span>
                </p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-base text-right mt-12">
              <h5>Desarrolladora</h5>
              <div class="invoice__company-info my-2">
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">42424242</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="GlobeIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">www.ladesarrolladoa.com</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="MapPinIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">Dirección local</span>
                </p>
              </div>
              <br />
            </div>
            <br />
            <vs-divider></vs-divider>
            <div class="p-2 w-full">
              <h6>Términos y condiciones:</h6>
              <div class="mt-2">
                {{ termsAndConditions }}
              </div>
            </div>
            <br />
          </div>
        </vx-card>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import vSelect from "vue-select";
import projectApi from "../services/project.api";

export default {
  data() {
    return {
      maxDaysQuote: 30,
      maxDaysOptioned: 2,
      termsAndConditions: "",
      termsAndConditionId: null,
      project: null,
      template: null,
      templates: [],
      paymentDaysReservation: [],
      paymentDay: 1,
      allowOptioned: false,
    };
  },
  components: {
    "v-select": vSelect
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  async mounted() {
    await this.getProject();
    this.templates = this.project.templates;
    this.template = this.project.quote_template;
  },
  methods: {
    async getProject() {
      const projectId = this.$store.state.project.currentProjectId;
      this.project = await api.get(projectId);

      this.maxDaysQuote = this.project.max_days_quote
        ? this.project.max_days_quote
        : 30;

      this.maxDaysOptioned = this.project.max_days_optioned
        ? this.project.max_days_optioned
        : 2;

      if (this.project.project_setting_values.length) {
        const generalQuoteDescription = this.project.project_setting_values.find(
          item => item.project_setting === "GENERAL_QUOTE_DESCRIPTION"
        );

        if (generalQuoteDescription) {
          this.termsAndConditions = generalQuoteDescription.value;
          this.termsAndConditionId = generalQuoteDescription.id;
        }
      }

      this.paymentDaysReservation = this.project.payment_days_reservation.length
        ? this.project.payment_days_reservation
        : [];
      
      this.allowOptioned = this.project.allow_optioned;
    },
    async updateTemplate() {
      try {
        let fields = {};
        fields["quote_template"] = this.template;
        const projectId = this.$store.state.project.currentProjectId;
        await api.updProject(projectId, fields);
        this.project.quote_template = this.template;
        this.$vs.notify({
          title: `📝 El campo template `,
          text: `Fue editado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `📝 El campo template `,
          text: `no ha podido ser editado debido a un error en el sistema.`,
          color: "danger"
        });
      }
    },
    async updateMaxDays() {
      const projectId = this.project.id;
      try {
        await api.updProject(projectId, {
          max_days_quote: this.maxDaysQuote
        });

        this.project = await api.get(projectId);

        this.$vs.notify({
          time: 3000,
          title: `¡Se ha guardado días de validez!`,
          text: `Dato guardado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar días de validez`,
          text: `No se ha podido actualizar el días de validez.`,
          color: "danger"
        });
      }
    },
    async updateMaxDaysOptioned() {
      const projectId = this.project.id;
      try {
        await api.updProject(projectId, {
          max_days_optioned: this.maxDaysOptioned
        });

        this.project = await api.get(projectId);

        this.$vs.notify({
          time: 6000,
          title: `¡Se ha guardado días de validez!`,
          text: `Dato guardado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar días de validez`,
          text: `No se ha podido actualizar el días de validez.`,
          color: "danger"
        });
      }
    },
    async updateTermAndConditions() {
      try {
        const projectId = this.project.id;
        if (this.termsAndConditionId) {
          const projectSetting = await api.updProjectSettingById(
            this.termsAndConditionId,
            {
              value: this.termsAndConditions.toString().trim(),
              project_setting: "GENERAL_QUOTE_DESCRIPTION"
            }
          );

          if (projectSetting.returning.length) {
            this.termsAndConditionId = projectSetting.returning[0].id;
          }
        } else {
          const projectSetting = await api.addProjectSetting(
            projectId,
            this.termsAndConditions.toString().trim(),
            "GENERAL_QUOTE_DESCRIPTION"
          );

          this.termsAndConditionId = projectSetting.id;
        }

        this.$vs.notify({
          time: 6000,
          title: `¡Se ha guardado términos y condiciones!`,
          text: `Dato guardado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar términos y condiciones`,
          text: `No se ha podido actualizar el días de validez.`,
          color: "danger"
        });
      }
    },
    async updateAllowOptioned(){
      const projectId = this.project.id;
      try{
        await api.updProject(projectId, {
          allow_optioned: this.allowOptioned
        });

        this.project = await api.get(projectId);

        this.$vs.notify({
          time: 6000,
          title: `¡Se ha guardado permitir opcionados!`,
          text: `Dato guardado correctamente.`,
          color: "success"
        });
      }
      catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar permitir opcionados`,
          text: `No se ha podido actualizar permitir opcionados.`,
          color: "danger"
        });
      }
    },
    getImageCover(images) {
      const coverImages = images.filter(
        item => item.project_image_gallery === "COVER"
      );
      if (!coverImages.length) return;
      return coverImages[0].img_url;
    },
    async removePaymentDays(day) {
      try {
        const projectId = this.project.id;

        if (this.paymentDaysReservation.length === 1) {
          this.$vs.notify({
            title: `Error al eliminar día de pago.`,
            text: `Es necesario un registro para la configuración.`,
            color: "danger"
          });
          return;
        }

        const newPaymentDays = this.paymentDaysReservation.filter(
          i => i !== day
        );

        await projectApi.updProject(projectId, {
          payment_days_reservation: newPaymentDays
        });

        await this.getProject();

        this.$vs.notify({
          time: 6000,
          title: `¡Se ha eliminado el día de pago!`,
          text: `Dato eliminado correctamente.`,
          color: "success"
        });
      } catch (error) {
        this.$vs.notify({
          title: `Error al eliminar día de pago.`,
          text: `Ha ocurrido un error al eliminar registro.`,
          color: "danger"
        });

        sentryCaptureException(error, "project");
      }
    },
    async addPaymentDayReservation() {
      try {
        const projectId = this.project.id;
        if (this.paymentDay === 0 || this.paymentDay > 31) {
          this.$vs.notify({
            title: `Error al agregar día de pago.`,
            text: `Día ${this.paymentDay} es incorrecto.`,
            color: "danger"
          });
          return;
        }

        const findPaymentDay = this.paymentDaysReservation.find(
          p => p === this.paymentDay
        );
        if (findPaymentDay) {
          this.$vs.notify({
            title: `Error al agregar día de pago.`,
            text: `Día ${this.paymentDay} ya se encuentra registrado.`,
            color: "danger"
          });
          return;
        }

        this.paymentDaysReservation.push(this.paymentDay);

        await projectApi.updProject(projectId, {
          payment_days_reservation: this.paymentDaysReservation
        });

        this.paymentDay = 1;

        this.$vs.notify({
          time: 6000,
          title: `¡Se ha guardado día de pago!`,
          text: `Dato guardado correctamente.`,
          color: "success"
        });

        await this.getProject();
      } catch (e) {
        this.$vs.notify({
          title: `Error al agregar día de pago.`,
          text: `Ha ocurrido un error al agregar registro.`,
          color: "danger"
        });

        sentryCaptureException(e, "project");
      }
    }
  }
};
</script>

<style lang="scss">
.imgLogoPreview {
  width: 90%;
  display: block;
  margin: 0 auto;
}
.LogoProyectQuote {
  width: 60%;
}
.ql-editor {
  height: 300px !important;
}
button.vs-component.vs-switch.vs-switch-primary {
    background-color: gray;
}

button.vs-component.vs-switch.vs-switch-primary.vs-switch-active {
    background-color: blue;
}
</style>
