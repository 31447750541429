<template>
  <div>
    <div>
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Bancos y tasas de interés:</h6>
              <vs-divider></vs-divider>
                <vs-row>
                  <div class="vx-col w-full">
                    <span>Nombre de entidad</span>
                    <v-select
                      v-validate="'required'"
                      v-model="bank"
                      @input="printDefaults"
                      :options="this.banks"
                    ></v-select>
                  </div>
                </vs-row>
              <div class="vx-row mb-4">
                <div class="vx-col w-full">
                  <vs-input
                    v-validate="'required'"
                    class="w-full"
                    label="% de tasa de interes"
                    type="number"
                    min="0"
                    max="100"
                    step=".1"
                    v-model="rate"
                    name="bank_rate"
                  />
                  <p>{{ rateValidation }}</p>
                </div>
              </div>
              <div class="vx-row mb-4">
                <div class="vx-col w-full">
                  <vs-input
                    min="1"
                    max="30"
                    class="w-full"
                    type="number"
                    label="Años máximos"
                    v-model="maxYears"
                    name="bank_years"
                  />
                  <p>{{ yearsValidation }}</p>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    @click.native="addFinancingProject()"
                    v-if="
                      bank != '' &&
                      rate != '' &&
                      maxYears != '' &&
                      validateRate &&
                      validateYears
                    "
                    color="success"
                    icon-pack="feather"
                    icon="icon-upload"
                    class="mr-3 mb-2"
                    >Ingresar nuevo</vs-button
                  >
                  <br />
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Bancos y tasas de interés actuales:</h6>
              <vs-divider></vs-divider>
              <vs-table
                single
                v-model="selected"
                pagination
                :max-items="itemsPerPage"
                :data="financingTypes"
                noDataText="🤦‍♂️ No hay tasas de interés creadas..."
              >
                <template slot="thead">
                  <vs-th>Entidad</vs-th>
                  <vs-th>Tasa de interés</vs-th>
                  <vs-th>Años máximo</vs-th>
                  <vs-th>Acción</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td :data="data[index].bank">
                      {{ data[index].bank.name }}
                    </vs-td>
                    <vs-td :data="data[index].interest_rate">
                      {{ data[index].interest_rate }} %
                    </vs-td>
                    <vs-td :data="data[index].max_years">
                      {{ data[index].max_years }} años
                    </vs-td>
                    <vs-td :data="data[index].active">
                      <vs-chip>{{
                        data[index].active ? "Activo" : "Inactivo"
                      }}</vs-chip>
                    </vs-td>
                    <vs-td>
                      <vs-row>
                        <vs-button
                          color="primary"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="p-1"
                          @click="popupUpdateBank(data[index])"
                        ></vs-button>
                        <vs-button
                          color="danger"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-trash"
                          class="p-1"
                          @click.native="
                            (popupFinancingDelete = true),
                              (financingTypeToDelete = data[index].id),
                              (financinNameToDelete = data[index].name)
                          "
                        ></vs-button>
                      </vs-row>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-alert
                :active.sync="showBankAlert"
                color="danger"
                icon="information"
              >
                <span
                  >Bancos y tasas de interés son requeridos. Debes dejar al
                  menos un registro.</span
                >
              </vs-alert>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>

      <vs-popup title="Eliminación" :active.sync="popupFinancingDelete">
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <p>
              ¿Desea eliminar a {{ financinNameToDelete }} de los metodos
              finacieros del proyecto?
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              @click.native="deleteFinancingType(financingTypeToDelete)"
              class="mt-4"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- update bank popup -->
      <vs-popup title="Actualización" :active.sync="popupBankUpdate">
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <span>Nombre de entidad</span>
            <v-select v-model="updBank" :options="this.banks"></v-select>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              v-validate="'required'"
              class="w-full"
              label="% de tasa de interes"
              type="number"
              min="0"
              max="100"
              step=".1"
              v-model.number="updRate"
              name="bank_rate"
            />
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              min="1"
              max="30"
              class="w-full"
              type="number"
              label="Años máximos"
              v-model.number="updYears"
              name="bank_years"
            />
          </div>
        </div>
        <div class="center con-switch pb-4">
          Activo
          <vs-switch v-model="active" class="toggle" />
        </div>
        <vs-button
          color="success"
          icon-pack="feather"
          icon="icon-save"
          type="filled"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container w-full"
          @click="updateFinancing"
          >Actualizar</vs-button
        >
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { bank } from "../../../services";
import { financingApi, api } from "../services";
import vSelect from "vue-select";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      banks: [],
      financingTypes: [],
      bank: {},
      rate: 0,
      maxYears: 1,
      popupFinancingDelete: false,
      financingTypeToDelete: "",
      financinNameToDelete: "",
      selected: null,
      itemsPerPage: 5,
      project: null,
      validateYears: true,
      validateRate: true,
      showBankAlert: false,
      popupBankUpdate: false,
      updBank: null,
      updRate: 0,
      updYears: 0,
      updFinancingId: null,
      active: false,
    };
  },
  components: {
    "v-select": vSelect,
  },
  watch: {
    maxYears(maxYears) {
      this.validateYears = true;
      if (maxYears > 30 || maxYears < 1) {
        this.validateYears = false;
      }
    },
    rate(rate) {
      this.validateRate = true;
      if (rate > 100 || rate < 0) {
        this.validateRate = false;
      }
    },
  },
  computed: {
    yearsValidation() {
      return this.maxYears > 30 || this.maxYears < 1
        ? "Los años deben estar en el rango entre 1 y 30."
        : "";
    },
    rateValidation() {
      return this.rate > 100 || this.rate < 0
        ? "La tasa de interés debe estar en el rango entre 0 y 100."
        : "";
    },
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);

    await this.getBanks();
    await this.fetchFinancingTypes();
  },
  methods: {
    printDefaults(bank) {
      this.rate = bank.rate;
      this.maxYears = bank.maxYears;
    },
    async addFinancingProject() {
      try {
        await financingApi.add(
          this.bank.label,
          this.rate,
          this.maxYears,
          this.$store.state.project.currentProjectId,
          this.bank.value
        );
        this.$vs.notify({
          title: `Financiamiento agregado`,
          text: `Se ha guardado el financiamiento al proyecto`,
          color: "success",
        });
        this.bank = {};
        this.rate = 0;
        this.maxYears = 1;
        await this.fetchFinancingTypes();
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al agregar financiamiento`,
          text: `No se ha podido agregar el financiamiento al proyecto`,
          color: "danger",
        });
      }
    },
    async deleteFinancingType(financingTypeId) {
      try {
        this.popupFinancingDelete = false;
        this.showBankAlert = false;

        if (this.financingTypes.length == 1) {
          this.showBankAlert = true;
          return;
        }

        await financingApi.remove(financingTypeId);

        this.$vs.notify({
          title: `Financiamiento eliminado`,
          text: `Se ha eliminado el financiamiento del proyecto`,
          color: "success",
        });

        await this.fetchFinancingTypes();
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al eliminar financiamiento`,
          text: `No se ha podido eliminar el financiamiento del proyecto`,
          color: "danger",
        });
      }
    },
    async fetchFinancingTypes() {
      this.financingTypes = await financingApi.list(
        this.$store.state.project.currentProjectId
      );
    },
    async getBanks() {
      const countryId = this.project.city.country.id;
      const banks = await bank.listByCountry(countryId);
      this.banks = banks.map((b) => {
        return {
          value: b.id,
          label: b.name,
          rate: b.default_interest_rate,
          maxYears: b.max_years,
        };
      });
    },
    popupUpdateBank(storedBank) {
      this.popupBankUpdate = true;
      this.updFinancingId = storedBank.id;
      this.updBank = { label: storedBank.bank.name, value: storedBank.bank.id };
      this.updRate = storedBank.interest_rate;
      this.updYears = storedBank.max_years;
      this.active = storedBank.active;
    },
    async updateFinancing() {
      try {
        await financingApi.upd(this.updFinancingId, {
          active: this.active,
          bankId: this.updBank.value,
          interestRate: this.updRate,
          maxYears: this.updYears,
        });

        this.$vs.notify({
          title: `Financiamiento actualizado`,
          text: `Se ha actualizado el financiamiento del proyecto`,
          color: "success",
        });

        this.popupBankUpdate = false;
        await this.fetchFinancingTypes();
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar financiamiento`,
          text: `No se ha podido actualizar el financiamiento del proyecto`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.toggle {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
