import * as Sentry from "@sentry/vue";

const sentryCaptureException = (error, section) => {
    Sentry.captureException(error, {
        tags: {
            section: section
        },
    });
}

export {
    sentryCaptureException
}