<template>
  <div>
    <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
      <div
        id="upload-images"
        class="mt-2"
        v-if="!crop || (crop && !imageIsSelected)"
      >
        <button
          type="button"
          class="relative mt-4 block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          @click="$refs.inputFileNative.click()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-12 w-12 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
            />
          </svg>
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Agregar archivo
          </span>
        </button>
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref="inputFileNative"
          :multiple="multiple"
          v-show="false"
          @change="onChangeContent($event)"
        />
      </div>
      <div id="crop-images" class="mt-2" v-if="crop && previewImages.length">
        <h3 class="text-lg font-medium text-gray-900" id="modal-title">
          Edición de imágen
        </h3>
        <VueCropper
          ref="cropper"
          :aspect-ratio="crop.ratioX / crop.ratioY"
          preview=".preview"
          :src="cropImageSrc"
        ></VueCropper>
        <div class="actions mt-2">
          <vs-button @click="zoom(0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-zoom-in"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path
                d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
              />
              <path
                fill-rule="evenodd"
                d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </vs-button>
          <vs-button class="ml-2" @click="zoom(-0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-zoom-out"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path
                d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
              />
              <path
                fill-rule="evenodd"
                d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </vs-button>
          <vs-button class="ml-2" @click="cropImage">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-crop"
              viewBox="0 0 16 16"
            >
              <path
                d="M3.5.5A.5.5 0 0 1 4 1v13h13a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2H3.5a.5.5 0 0 1-.5-.5V4H1a.5.5 0 0 1 0-1h2V1a.5.5 0 0 1 .5-.5zm2.5 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4H6.5a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </vs-button>
        </div>
      </div>
      <div
        id="loading"
        class="bg-white flex space-x-12 p-12 justify-center items-center"
        v-if="loading"
      >
        <div class="flex items-center justify-center space-x-2 animate-pulse">
          <div class="w-5 h-5 bg-green-500 rounded-full"></div>
          <div class="w-5 h-5 bg-green-500 rounded-full"></div>
          <div class="w-5 h-5 bg-green-500 rounded-full"></div>
        </div>
      </div>
      <div id="preview-images" class="mt-2" v-if="previewImages.length">
        <h3 class="text-lg font-medium text-gray-900" id="modal-title">
          Previsualización
        </h3>
        <div class="flex">
          <div v-for="image of previewImages" :key="image.id">
            <img class="object-contain h-24 w-24" :src="image.encode" />
          </div>
        </div>
      </div>
      <div id="error-message" class="mt-4">
        <span class="text-red-800	text-base">{{ error }}</span>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-lg px-6 py-2 bg-green-500 font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
        @click="upload"
      >
        Confirmar archivo
      </button>
      <button
        v-if="buttonCancel"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-lg font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto"
        @click="cancelUpload"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import S3 from "aws-s3";
import VueCropper from "vue-cropperjs";
import { resizeImage } from "../helpers/jimp";
import { sentryCaptureException } from "../helpers/Sentry";
export default {
  props: {
    buttonCancel: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dir: {
      type: String,
      default: "default",
      required: true
    },
    crop: {
      type: Object,
      validator(value) {
        let allowedKeys = ["ratioX", "ratioY"];

        if (!Object.entries(value).length) {
          return false;
        }

        const includeKeys = Object.keys(value).every(e =>
          allowedKeys.includes(e)
        );

        return includeKeys;
      }
    },
    resize: {
      type: Object,
      validator(value) {
        let allowedKeys = ["width", "height", "quality"];

        if (!Object.entries(value).length) {
          return false;
        }

        const includeKeys = Object.keys(value).every(e =>
          allowedKeys.includes(e)
        );

        return includeKeys;
      }
    }
  },
  components: {
    VueCropper
  },
  mounted() {
    try {
      if (this.multiple && this.crop) {
        this.$el.remove();
        throw "Crop prop is not supported with images multiple.";
      }
    } catch (e) {
      sentryCaptureException(e, "images-component");
    }
  },
  data() {
    return {
      error: "",
      previewImages: [],
      uploadImages: [],
      loading: false,
      cropImageSrc: null,
      imageIsSelected: false,
      cropImageCut: false
    };
  },
  computed: {
    S3bucket() {
      return new S3({
        bucketName: "flattlo-app",
        dirName: this.dir,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      });
    }
  },
  methods: {
    async onChangeContent({ target }) {
      this.uploadImages = target.files;

      if (!this.uploadImages.length) return;

      let readers = [];
      for (const file of this.uploadImages) {
        readers.push(this.fileAsDataURL(file));
      }

      this.previewImages = await Promise.all(readers);
      this.imageIsSelected = true;

      if (this.crop) {
        this.cropImageSrc = this.previewImages[0].encode;
        this.$refs.cropper.replace(this.previewImages[0].encode);
      }
    },
    fileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = ({ target }) => {
          resolve({
            encode: target.result,
            id: Date.now()
          });
        };

        reader.onerror = e => {
          reject(e);
        };

        reader.readAsDataURL(file);
      });
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    async cropImage() {
      try {
        const imageEncode = this.$refs.cropper.getCroppedCanvas().toDataURL();
        const fileName = Date.now();

        this.previewImages.splice(0, 1, {
          id: fileName,
          encode: imageEncode
        });

        const file = await this.dataURIToFile(imageEncode);
        this.uploadImages = [file];
        this.cropImageCut = true;
      } catch (e) {
        sentryCaptureException(e, "images-component");
      }
    },
    async upload() {
      try {
        this.cropImage();
        if (!this.uploadImages.length) {
          this.error = "Debes seleccionar al menos un archivo *";
          return;
        }
        
        let images = [];
        this.error = "";
        this.loading = true;

        if (this.resize) {
          let resizeImages = [];
          for (const image of this.previewImages) {
            resizeImages.push(
              resizeImage(
                image.encode,
                this.resize.width,
                this.resize.height,
                this.resize.quality
              )
            );
          }
          this.uploadImages = await Promise.all(resizeImages);
        }

        for (const image of this.uploadImages) {
          const imageName = Date.now();

          images.push(this.S3bucket.uploadFile(image, imageName));
        }

        const uploadedImages = await Promise.all(images);

        this.loading = false;
        this.uploadImages = [];
        this.previewImages = [];

        this.$emit("upload", uploadedImages);
      } catch (e) {
        sentryCaptureException(e, "images-component");
        this.loading = false;
        this.error = e.toString();
      }
    },
    cancelUpload() {
      this.loading = false;
      this.uploadImages = [];
      this.previewImages = [];

      this.$emit("cancel-upload", true);
    },
    async dataURIToFile(data) {
      let type = data.split(";", [1])[0];
      type = type.split(":", [2])[1];

      const date = new Date();
      const fetchBase64 = await fetch(data);
      const buffer = await fetchBase64.arrayBuffer();

      return new File([buffer], date.getDate(), { type });
    }
  }
};
</script>

<style lang="scss">
.fixed.inset-0.overflow-y-auto {
    z-index: 53000 !important;
}
</style>
