<template>
  <div>
    <vx-card>
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <div class="col-12">
          <h6>Información general </h6>
        </div>
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <span>Etapa del Proyecto</span> 
          <v-select
            class="vSelector mb-2 w-full"
            v-model="stage"
            :reduce="(stage) => stage.value"
            :options="projectStages"
          ></v-select>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.stage == stage && stage != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-3"
            @click="editProject('stage', stage, 'etapa del proyecto')"
          >
        </vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <span>Tipo de Proyecto</span>
          <v-select
            class="vSelector mb-2"
            :reduce="(t) => t.value"
            v-model="type"
            :options="this.projectTypes"
          ></v-select>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.type == type && type != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-3"
            @click="editProject('type', type, 'etapa del proyecto')"
          ></vs-button>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
            <vs-input
              size="large"
              icon-pack="feather"
              class="w-full"
              icon="icon-edit"
              label="Nombre del Proyecto"
              color="primary"
              v-validate="'required'"
              name="project_name"
              v-model="name"
              :placeholder="name"
            />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.name == name && name != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-5"
            @click="editProject('name', name, 'nombre del proyecto')"
          ></vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
              size="large"
              icon-pack="feather"
              class="w-full"
              icon="icon-layers"
              label="Cantidad de Niveles"
              color="primary"
              type="number"
              v-validate="'required'"
              name="project_level"
              v-model="levels"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.levels == levels && levels != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-5"
            @click.native="editProject('levels', levels, 'total de niveles')"
          ></vs-button>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
            <vs-input
              size="large"
              icon-pack="feather"
              class="w-full"
              icon="icon-grid"
              label="Cantidad de apartamentos"
              color="primary"
              type="number"
              v-validate="'required'"
              name="project_apartments"
              v-model="apartments_available"
            />
          </vs-col>
          
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.apartments_available == apartments_available && apartments_available != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6 m-2"
            @click.native="
              editProject(
                'apartments_available',
                apartments_available,
                'total de apartamentos'
              )
            "
          ></vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <span>Clasificación de proyecto</span>
          <v-select
            v-model="classification"
            :options="this.classifications"
          ></v-select>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.classification == classification && classification != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6 m-2"
            @click.native="editProject('classification', classification, 'clasificación')"
          ></vs-button>
        </vs-col>
      </vs-row>


      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
            <vs-input
              size="large"
              icon-pack="feather"
              class="w-full"
              type="date"
              icon="icon-calendar"
              label="Fecha de Lanzamiento"
              color="primary"
              v-validate="'required'"
              required="true"
              name="date_release"
              v-model="dateRelease"
            />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="this.getDateFormat(project.date_release) == this.getDateFormat(dateRelease) && dateRelease != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="
              editProject('date_release', dateRelease, 'fecha de lanzamiento')
            "
          ></vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
            size="large"
            icon-pack="feather"
            class="w-full"
            icon="icon-calendar"
            label="Fecha de Finalización"
            color="primary"
            v-validate="'required'"
            required="true"
            name="date_ending"
            type="date"
            v-model="dateEnding"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="this.getDateFormat(project.date_ending) == this.getDateFormat(dateEnding) && dateEnding != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="editProject('date_ending', dateEnding, 'fecha de finalización')"
          ></vs-button>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
            size="large"
            icon-pack="feather"
            class="w-full"
            icon="icon-globe"
            label="Website"
            color="primary"
            v-validate="'required'"
            required="true"
            name="project_website"
            v-model="website"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >  
          <vs-button
            :disabled="project.website == website && website != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="editProject('website', website, 'sitio web')"
          ></vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
            size="large"
            icon-pack="feather"
            class="w-full"
            icon="icon-globe"
            label="Whatsapp Número"
            color="primary"
            v-validate="'required'"
            required="true"
            name="project_whatsapp"
            v-model="whatsAppNumber"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >  
            <vs-button
              :disabled="
                String(
                  getAttribute(
                    project.project_setting_values,
                    'WHATSAPP_SHOW_ROOM'
                  )
                    ? getAttribute(
                        project.project_setting_values,
                        'WHATSAPP_SHOW_ROOM'
                      ).value
                    : ''
                ) == whatsAppNumber && whatsAppNumber != ''
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-6"
              @click="
                editProjectSetting(
                  whatsAppSettingId,
                  whatsAppNumber,
                  ' whatsApp número',
                  'WHATSAPP_SHOW_ROOM'
                )
              "
            >
            </vs-button>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center" class="mb-4">

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
            size="large"
            class="w-full"
            label="Precio desde"
            color="primary"
            v-validate="'required'"
            required="true"
            name="project_website"
            v-model.number="priceFrom"
            min="0"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="
              parseInt(
                getAttribute(project.project_setting_values, 'PRICE_FROM')
                  ? getAttribute(project.project_setting_values, 'PRICE_FROM')
                      .value
                  : 0
              ) == priceFrom
            "
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="
              editProjectSetting(
                priceFromSettingId,
                priceFrom,
                ' precio desde',
                'PRICE_FROM'
              )
            "
          ></vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
            <vs-input
              size="large"
              class="w-full"
              label="Precio hasta"
              color="primary"
              v-validate="'required'"
              required="true"
              name="price_to"
              v-model.number="priceTo"
              min="0"
            />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="
              parseInt(
                getAttribute(project.project_setting_values, 'PRICE_TO')
                  ? getAttribute(project.project_setting_values, 'PRICE_TO')
                      .value
                  : 0
              ) == priceTo
            "
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="
              editProjectSetting(
                priceToSettingId,
                priceTo,
                ' precio hasta',
                'PRICE_TO'
              )
            "
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row class="mt-6">
        <vs-col class="flex sm:w-full px-2">
          <div class="w-full sm:w-full mb-3">
            <span>Descripción del Proyecto:</span>
            <vs-textarea
              counter="250"
              placeholder="Descripción"
              v-model="description"
              :counter-danger.sync="counterDanger"
              height="350"
            />
          </div>
          <vs-button
            v-if="project.description != description && description != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-8 m-2"
            @click="editProject('description', description, 'descripción')"
          ></vs-button>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2">
          <div class="w-full sm:w-full px-2">
            <Popup
              :title="`Logo de proyecto`"
              :showPopup="logoPopup"
              @close="closePopUp"
            >
              <ImageUpload
                :resize="{
                  width: 700,
                  height: 700,
                  quality: 60,
                }"
                :crop="{
                  ratioX: 1,
                  ratioY: 1,
                }"
                :dir="logoDir"
                @cancel-upload="cancelUpload"
                @upload="uploadImage"
                slot="content"
              />
            </Popup>
            <div class="text-center mt-4">
              <img
                class="responsive rounded-lg imagePreview"
                :src="logoProject.location"
              />
            </div>
            <vs-button
              @click="logoPopup = true"
              class="w-full mt-6"
              color="success"
              icon-pack="feather"
              icon="icon-upload-cloud"
              >Reemplazar logo</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
      </vx-card>
    <br />

    <vx-card>
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <div class="col-12">
          <h6> Razón Social </h6>
        </div>
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          <vs-input
            icon-pack="feather"
            class="w-full"
            icon="icon-edit"
            label="Nombre"
            color="primary"
            v-validate="'required'"
            name="project_business_name"
            v-model="businessName"
            :placeholder="businessName"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.business_name == businessName && businessName != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="editProject('business_name', businessName, 'razón social')"
          >
          </vs-button>
        </vs-col>

        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="12" vs-xs="10" >
          
            <vs-input
              icon-pack="feather"
              class="w-full"
              icon="icon-map-pin"
              label="Dirección"
              color="primary"
              v-validate="'required'"
              name="project_business_address"
              v-model="businessAddress"
              :placeholder="businessAddress"
            />
          
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="12" vs-xs="2" >
          <vs-button
            :disabled="project.business_address == businessAddress && businessAddress != ''"
            icon-pack="feather"
            color="success"
            icon="icon-save"
            class="mt-6"
            @click="
              editProject(
                'business_address',
                businessAddress,
                'dirección de la razón social'
              )
            "
          ></vs-button>
        </vs-col>
      </vs-row>
    </vx-card>

    <br />
    <vx-card>
        <vs-row>
          <div class="col-12">
            <h6> Ubicacion y moneda </h6>
          </div>
        </vs-row>

        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <span>País</span>
              <v-select
                placeholder="País"
                class="mt-1 mb-3"
                disabled
                :reduce="(country) => country.value"
                :options="countries"
                v-model="country"
              ></v-select>
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <span>Seleccione Ciudad</span>
              <v-select
                placeholder="Ciudad"
                autocomplete="off"
                class="mt-1 mb-3"
                :options="cities"
                v-model="city"
                :reduce="(c) => c.value"
                @input="changeZones"
              ></v-select>
            </div>
            <vs-button
              v-if="project.city_id != city && city != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="editProject('city_id', city, 'ciudad de proyecto')"
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <span>Seleccione Zona</span>
              <v-select
                placeholder="Zona"
                class="mt-1 mb-3"
                :disabled="!zones.length"
                v-model="zone"
                :reduce="(zone) => zone.value"
                @input="changeNeighborhood"
                :options="this.zones"
              ></v-select>
            </div>
            <vs-button
              v-if="
                (project.city_zone && project.city_zone.name != zone) ||
                (zone != null && !project.city_zone)
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="editProject('city_zone_id', zone, 'zona de proyecto')"
            ></vs-button>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <span>Seleccione Vecindario</span>
              <v-select
                placeholder="Vecindario"
                autocomplete="off"
                class="mt-1 mb-3"
                :disabled="!neighborhoods.length"
                :reduce="(n) => n.value"
                v-model="neighborhood"
                :options="this.neighborhoods"
              ></v-select>
            </div>
            <vs-button
              v-if="
                (project.suburb && project.suburb.id != neighborhood) ||
                (neighborhood != null && !project.suburb)
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                editProject('suburb_id', neighborhood, 'vecindario de proyecto')
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <vs-input
                icon-pack="feather"
                class="w-full"
                icon="icon-map-pin"
                label="Dirección exacta"
                color="primary"
                v-validate="'required'"
                name="project_address"
                v-model="address"
                placeholder="Direccion"
              />
            </div>
            <vs-button
              v-if="project.address != address && address != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="editProject('address', address, 'dirección del proyecto')"
            ></vs-button>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <span>Moneda</span>
              <v-select
                placeholder="Seleccione moneda"
                class="mt-1 mb-3"
                v-model="countryCurrency"
                :disabled="!country"
                :options="countryCurrencies"
              ></v-select>
            </div>
            <vs-button
              v-if="
                countryCurrency &&
                project.country_currency_id != countryCurrency.value
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                editProject(
                  'country_currency_id',
                  countryCurrency.value,
                  'moneda del proyecto'
                )
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <br />
        <span>
          Mueve el marcador para actualizar ubicación de {{ project.name }}
        </span>
        <gmap-map
          :center="center"
          :zoom="12"
          style="width: 100%; height: 300px"
          class="mt-4 mb-2"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          ></gmap-info-window>
          <gmap-marker
            :position="center"
            :draggable="true"
            :clickable="true"
            @drag="updateCoordinates"
          ></gmap-marker>
        </gmap-map>
  
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { api } from "../services";
import { country } from "../../../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";
import Popup from "../../../components/Popup.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import dayjs from "../../../helpers/days";

export default {
  data() {
    return {
      project: {},
      projectStages: [],
      projectTypes: [],
      classifications: ["VIS", "MUVI", "NO VIS"],
      stage: "",
      type: "",
      classification: "",
      name: "",
      description: null,
      website: "",
      address: "",
      country: "",
      city: null,
      zone: null,
      neighborhood: null,
      levels: null,
      apartments_available: null,
      coordinates: { lat: 6.2435163, lng: -75.6931375 },
      countries: [],
      cities: [],
      zones: [],
      neighborhoods: [],
      counterDanger: false,
      center: { lat: 6.2435163, lng: -75.6931375 },
      infoContent: "",
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      newPosition: false,
      n_parkings_assign: "",
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: { width: 0, height: -35 },
      },
      whatsAppNumber: null,
      whatsAppSettingId: null,
      logoPopup: false,
      logoProject: null,
      countryCurrency: null,
      countryCurrencies: [],
      dateRelease: "",
      dateEnding: "",
      businessName: "",
      businessAddress: "",
      priceFrom: 0,
      priceFromSettingId: "",
      priceTo: 0,
      priceToSettingId: "",
    };
  },
  components: {
    vSelect,
    Popup,
    ImageUpload,
  },
  computed: {
    logoDir() {
      const projectName = slugify(this.name);
      const developerName = slugify(
        this.$store.getters["auth/getUser"].developerName
      );

      return `${developerName}/projects/${projectName}/gallery`;
    },
  },
  async mounted() {
    await this.getProject();
  },
  methods: {
    async getProject() {
      try {
        const countries = await country.getCountries();
        this.countries = countries.map((obj) => {
          return { label: obj.name, value: obj.id, cities: obj.cities };
        });

        const projectStages = await api.getAvailableProjectStages();
        this.projectStages = projectStages.map((obj) => {
          return { label: obj.name, value: obj.id };
        });

        const projectTypes = await api.getTypes();
        this.projectTypes = projectTypes.map((obj) => {
          return { label: obj.name, value: obj.id };
        });

        const prj = await api.get(this.$store.state.project.currentProjectId);
        this.project = prj;
        this.name = prj.name;
        this.description = prj.description;
        this.website = prj.website;
        this.stage = prj.stage;
        this.type = prj.type;
        this.classification = prj.classification;
        this.address = prj.address;
        this.levels = prj.levels;
        this.apartments_available = prj.apartments_available;
        this.dateRelease = this.getDateFormat(prj.date_release);
        this.dateEnding = this.getDateFormat(prj.date_ending);
        this.businessName = prj.business_name;
        this.businessAddress = prj.business_address;
        const projectLat = prj.location.coordinates[0];
        const projectLng = prj.location.coordinates[1];

        this.imageCover(this.project.project_images);

        if (projectLat && projectLng) {
          this.center.lat = projectLat;
          this.center.lng = projectLng;
        }

        this.changeCities(prj.city.country.id);
        await this.changeZones(prj.city.id);

        if (prj.city_zone) {
          await this.changeNeighborhood(prj.city_zone.id);
          this.zone = prj.city_zone.name;
        }

        this.country = prj.city.country.id;
        this.city = prj.city.id;

        this.neighborhood = prj.suburb ? prj.suburb.id : null;
        const whatsappSetting = this.project.project_setting_values.length
          ? this.project.project_setting_values.filter(
              (i) => i.project_setting === "WHATSAPP_SHOW_ROOM"
            )
          : null;

        if (whatsappSetting) {
          this.whatsAppNumber = whatsappSetting.length
            ? whatsappSetting[0].value
            : null;
          this.whatsAppSettingId = whatsappSetting.length
            ? whatsappSetting[0].id
            : null;
        }

        const priceFromSetting = this.project.project_setting_values.length
          ? this.project.project_setting_values.filter(
              (i) => i.project_setting === "PRICE_FROM"
            )
          : null;

        if (priceFromSetting) {
          this.priceFrom = priceFromSetting.length
            ? parseInt(priceFromSetting[0].value)
            : 0;
          this.priceFromSettingId = priceFromSetting.length
            ? priceFromSetting[0].id
            : null;
        }

        const priceToSetting = this.project.project_setting_values.length
          ? this.project.project_setting_values.filter(
              (i) => i.project_setting === "PRICE_TO"
            )
          : null;

        if (priceToSetting) {
          this.priceTo = priceToSetting.length
            ? parseInt(priceToSetting[0].value)
            : 0;
          this.priceToSettingId = priceToSetting.length
            ? priceToSetting[0].id
            : null;
        }

        const currencies = await country.getCurrencies(this.country);

        this.countryCurrencies = currencies.map((i) => {
          return {
            label: `${i.currency.code} ${i.currency.name}`,
            value: i.id,
          };
        });

        const defaultCurrency = this.countryCurrencies.find(
          (c) => c.value == this.project.country_currency_id
        );

        this.countryCurrency = defaultCurrency ? defaultCurrency : null;
      } catch (e) {
        sentryCaptureException(e, "project");
      }
    },
    changeCities(countryId) {
      const country = this.countries.filter((obj) => obj.value == countryId);
      this.cities = country[0].cities.map((obj) => {
        return { label: obj.name, value: obj.id };
      });
      this.zones = [];
      this.neighborhoods = [];
      this.zone = null;
      this.neighborhood = null;
    },
    async changeZones(cityId) {
      const zones = await country.getZones(cityId);
      this.zones = zones.map((obj) => {
        return { label: obj.name, value: obj.id, neighborhoods: obj.suburbs };
      });
      this.zone = null;
      this.neighborhoods = [];
      this.neighborhood = null;
    },
    changeNeighborhood(zoneId) {
      const zone = this.zones.filter((obj) => obj.value == zoneId);
      if (zone.length) {
        this.neighborhoods = zone[0].neighborhoods.map((obj) => {
          return { label: obj.name, value: obj.id };
        });
      }
      this.neighborhood = null;
    },
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
    },
    async updateCoordinates(location) {
      try {
        const gisLocation = {
          type: "Point",
          crs: {
            type: "name",
            properties: { name: "urn:ogc:def:crs:EPSG::4326" },
          },
        };
        gisLocation.coordinates = [
          location.latLng.lat(),
          location.latLng.lng(),
        ];
        await api.updProject(this.$store.state.project.currentProjectId, {
          location: gisLocation,
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `El campo ubicación`,
          text: `No pudo ser actualizado en este momento.`,
          color: "danger",
        });
      }
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.infoText;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    async editProjectSetting(settingId, value, text, settingKey = "") {
      const projectId = this.$store.state.project.currentProjectId;
      try {
        const findAttributes = this.getAttribute(
          this.project.project_setting_values,
          settingKey
        );

        if (!findAttributes) {
          const newAttr = await api.addProjectSetting(
            projectId,
            String(value),
            settingKey
          );
          if (settingKey === "PRICE_TO") this.priceToSettingId = newAttr.id;
          if (settingKey === "PRICE_FROM") this.priceFromSettingId = newAttr.id;
          if (settingKey === "WHATSAPP_SHOW_ROOM")
            this.whatsAppSettingId = newAttr.id;
        } else {
          await api.updProjectSettingById(settingId, {
            value: String(value),
          });
        }

        this.project = await api.get(projectId);
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `Fue editado correctamente.`,
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `no ha podido ser editado debido a un error en el sistema.`,
          color: "danger",
        });
      }
    },
    async editProject(field, value, text) {
      try {
        let fields = {};
        fields[field] = value;
        const projectId = this.$store.state.project.currentProjectId;
        await api.updProject(projectId, fields);

        if (!this.neighborhood) {
          await api.updProject(projectId, { suburb_id: null });
        }

        if (!this.zone) {
          await api.updProject(projectId, { city_zone_id: null });
        }

        await this.getProject();

        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `Fue editado correctamente.`,
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `no ha podido ser editado debido a un error en el sistema.`,
          color: "danger",
        });
      }
    },
    imageCover(images) {
      const coverImages = images.filter(
        (item) => item.project_image_gallery === "COVER"
      );

      if (!coverImages.length) return;

      this.logoProject = {
        location: coverImages[0].img_url,
      };
    },
    async addImageToProject() {
      try {
        const logoImage = this.project.project_images.filter(
          (i) => i.project_image_gallery === "COVER"
        );
        if (!logoImage.length) {
          await api.addProjectImage({
            title: this.title,
            img_url: this.logoProject.location,
            project_image_gallery: "COVER",
            project_id: this.project.id,
          });
        } else {
          const fields = {
            img_url: this.logoProject.location,
          };
          await api.updProjectImage(this.project.id, fields, "COVER");
        }
        this.$vs.notify({
          time: 6000,
          title: `Imagen guardada.`,
          text: `La imagen fue guardada correctamente.`,
          color: "success",
        });
        this.title = "";
        this.project = await api.get(
          this.$store.state.project.currentProjectId
        );
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible guardar la imagen.`,
          color: "danger",
        });
      }
    },
    closePopUp() {
      this.logoPopup = false;
    },
    cancelUpload() {
      this.logoPopup = false;
    },
    async uploadImage(images) {
      this.logoProject = images.length ? images[0] : null;
      await this.addImageToProject();
      this.logoPopup = false;
    },
    getDateFormat(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    getAttribute(attributes, key) {
      return attributes.find((attr) => attr.project_setting === key) || null;
    },
  },
};
</script>

<style lang="scss">
.imagePreview {
  width: 60%;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}
.vSelector {
  width: 100%;
}
.vuesax-app-is-ltr .vs-input--input.hasIcon + .vs-input--placeholder {
  color: #000 !important;
}
.removeImgBtn {
  position: absolute;
  margin-left: 41.66666%;
}

.mt-6 {
  margin-top: 1.40rem !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .removeImgBtn {
    position: absolute;
    margin-left: 70.777777%;
  }
}
</style>
