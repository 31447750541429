<template>
  <div>
    <div>
      <vx-card>
        <vs-row vs-w="12">
          <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
            <h6>Reformas</h6>
            <br />
            <div class="flex">
              <label
                >¿Permitir reformas en {{ project.name }}? &nbsp;&nbsp;</label
              >
              <vs-switch v-model="projectCustomExtras" />
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <br />
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="5"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Formulario de Adicionales:</h6>
              <vs-divider></vs-divider>
              <br />
              <div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required|alpha'"
                      class="w-full"
                      label="Titulo"
                      v-model="name"
                      name="extras_name"
                    />
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required'"
                      type="number"
                      class="w-full"
                      label="Valor"
                      v-model.number="value"
                      name="extras_amount"
                    />
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span>Descripcion</span>
                    <vs-textarea class="w-full" v-model="description" />
                  </div>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    @click.native="addProjectExtra()"
                    v-if="name != '' && value != ''"
                    color="success"
                    icon-pack="feather"
                    icon="icon-upload"
                    class="mr-3 mb-2"
                    >Crear Adicional</vs-button
                  >
                  <br />
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="7"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Productos adicionales actuales:</h6>
              <vs-divider></vs-divider>
              <vs-table
                single
                v-model="selected"
                pagination
                :max-items="itemsPerPage"
                :data="projectExtras"
                noDataText="🤦‍♂️ No hay productos adicionales creados..."
              >
                <template slot="thead">
                  <vs-th>Titulo</vs-th>
                  <vs-th>Valor</vs-th>
                  <vs-th>Estado</vs-th>
                  <vs-th>Acción</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td :data="data[index].name">
                      {{ data[index].name }}
                    </vs-td>
                    <vs-td :data="data[index].value">
                      {{ `$ ${data[index].value}` }}
                    </vs-td>
                    <vs-td :data="data[index].active">
                      <vs-chip>{{
                        data[index].active ? "Activo" : "Inactivo"
                      }}</vs-chip>
                    </vs-td>
                    <vs-td>
                      <vs-row>
                        <vs-button
                          color="primary"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="p-1"
                          @click="popupUpdateExtra(data[index])"
                        ></vs-button>
                        <vs-button
                          color="danger"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-trash"
                          class="p-1"
                          @click.native="
                            (popupExtraDelete = true),
                              (ExtraTypeToDelete = data[index].id),
                              (ExtraNameToDelete = data[index].name)
                          "
                        ></vs-button>
                      </vs-row>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-popup title="Eliminación" :active.sync="popupExtraDelete">
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <p>
              ¿Desea eliminar <b>{{ ExtraNameToDelete }}</b> de los acabados
              disponibles?
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              @click.native="removeExtra(ExtraTypeToDelete)"
              class="mt-4"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- update extra popup -->
      <vs-popup title="Actualización" :active.sync="popupExtraUpdate">
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Título"
              min="0"
              max="100"
              step=".1"
              v-model="updExtraName"
              name="extraName"
            />
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              v-validate="'required'"
              class="w-full"
              label="Valor"
              type="number"
              min="0"
              max="100"
              step=".1"
              v-model.number="updExtraValue"
              name="extraValue"
            />
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <span>Descripcion</span>
            <vs-textarea class="w-full" v-model="updExtraDescription" />
          </div>
        </div>
        <div class="center con-switch pb-4">
          Activo
          <vs-switch v-model="extraActive" class="toggle" />
        </div>
        <vs-button
          color="success"
          icon-pack="feather"
          icon="icon-save"
          type="filled"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container w-full"
          @click="updateExtra"
          >Actualizar</vs-button
        >
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { extrasApi, api } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      projectExtras: [],
      name: "",
      value: "",
      description: "",
      active: null,
      popupExtraDelete: false,
      ExtraTypeToDelete: "",
      ExtraNameToDelete: "",
      projectCustomExtras: true,
      itemsPerPage: 5,
      selected: null,
      project: null,
      popupExtraUpdate: false,
      updExtraId: null,
      updExtraName: "",
      updExtraValue: "",
      updExtraDescription: "",
      extraActive: false,
    };
  },
  async mounted() {
    await this.fetchProjectExtras();
    await this.getProjectCustomExtras();
  },
  methods: {
    async fetchProjectExtras() {
      this.projectExtras = await extrasApi.list(
        this.$store.state.project.currentProjectId
      );
    },
    async getProjectCustomExtras() {
      this.project = await api.get(this.$store.state.project.currentProjectId);
      this.projectCustomExtras = this.project.custom_extras;
    },
    async addProjectExtra() {
      try {
        await extrasApi.add(
          this.name,
          true,
          this.value,
          this.description,
          this.$store.state.project.currentProjectId
        );
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha creado un Adicional!`,
          text: `Adicional ingresado correctamente.`,
          color: "success",
        });
        this.name = "";
        this.value = 0;
        this.description = "";
        this.active = true;
        await this.fetchProjectExtras();
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          time: 6000,
          title: `Error creando el adicional`,
          text: `No se ha podido crear el adicional`,
          color: "danger",
        });
      }
    },
    async removeExtra(extraId) {
      try {
        await extrasApi.remove(extraId);
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha eliminado el Adicional!`,
          text: `Adicional eliminado correctamente.`,
          color: "success",
        });
        await this.fetchProjectExtras();
        this.ExtraTypeToDelete = "";
        this.ExtraNameToDelete = "";
        this.popupExtraDelete = false;
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al eliminar el adicional`,
          text: `No se ha podido eliminar el adicional del proyecto`,
          color: "danger",
        });
      }
    },
    popupUpdateExtra(extra) {
      console.log(extra);
      this.popupExtraUpdate = true;
      this.updExtraId = extra.id;
      this.updExtraName = extra.name;
      this.updExtraValue = extra.value;
      this.updExtraDescription = extra.description;
      this.extraActive = extra.active;
    },
    async updateExtra() {
      try {
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha actualizado el Adicional!`,
          text: `Adicional actualizado correctamente.`,
          color: "success",
        });

        await extrasApi.upd(this.updExtraId, {
          name: this.updExtraName,
          value: this.updExtraValue,
          description: this.updExtraDescription,
          active: this.extraActive
        });

        await this.fetchProjectExtras();
        
        this.popupExtraUpdate = false;
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar adicional`,
          text: `No se ha podido actualizar adicional`,
          color: "danger",
        });
      }
    }
  },
  watch: {
    async projectCustomExtras(value) {
      const projectId = this.$store.state.project.currentProjectId;
      await api.updProject(projectId, { custom_extras: value });
    },
  },
};
</script>

<style scoped>
.toggle {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
