<template>
  <div>
    <div>
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="5"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Formulario de descuentos generales:</h6>
              <vs-divider></vs-divider>
              <div class="center con-switch">
                Permitir descuentos generales
                <vs-switch v-model="enableDiscount" />
              </div>
              <div class="mt-4">
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required|alpha'"
                      class="w-full"
                      label="Titulo de descuento"
                      v-model.trim="name"
                      :disabled="!enableDiscount"
                      name="discount_name"
                    />
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <label for="type">Tipo de Descuento</label>
                    <v-select
                      v-model="type"
                      :options="this.discountTypes"
                      :disabled="!enableDiscount"
                    ></v-select>
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required'"
                      type="number"
                      class="w-full"
                      label="Valor del Descuento"
                      v-model.number="value"
                      :disabled="!enableDiscount"
                      name="discount_amount"
                    />
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span>Descripción del Proyecto:</span>
                    <vs-textarea
                      counter="250"
                      placeholder=""
                      v-model="description"
                      height="350"
                    />
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <vs-button
                      @click.native="addProjectDiscount()"
                      v-if="name != '' && value != '' && type.value != ''"
                      color="success"
                      icon-pack="feather"
                      icon="icon-upload"
                      class="mr-3 mb-2"
                      >Crear descuento</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="7"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Descuentos generales actuales:</h6>
              <vs-divider></vs-divider>
              <vs-table
                single
                v-model="selected"
                pagination
                :max-items="itemsPerPage"
                :data="discounts"
                noDataText="🤦‍♂️ No hay descuentos creados..."
              >
                <template slot="thead">
                  <vs-th>Titulo</vs-th>
                  <vs-th>Valor</vs-th>
                  <vs-th>Estado</vs-th>
                  <vs-th>Acción</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td :data="data[index].name">
                      {{ data[index].name }}
                    </vs-td>
                    <vs-td :data="data[index].value">
                      {{
                        data[index].type === "AMOUNT"
                          ? `$ ${data[index].value}`
                          : `${data[index].value} %`
                      }}
                    </vs-td>
                    <vs-td :data="data[index].active">
                      <vs-chip>{{
                        data[index].active ? "Activo" : "Inactivo"
                      }}</vs-chip>
                    </vs-td>
                    <vs-td>
                      <vs-row>
                        <vs-button
                          color="primary"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="p-1"
                          @click="popUpupdateDiscount(data[index].id)"
                        ></vs-button>
                        <vs-button
                          color="danger"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-trash"
                          class="p-1"
                          @click.native="
                            (popupDiscountDelete = true),
                              (DiscountTypeToDelete = data[index].id),
                              (DiscountNameToDelete = data[index].name)
                          "
                        ></vs-button>
                      </vs-row>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-popup title="Eliminación" :active.sync="popupDiscountDelete">
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <p>
              ¿Desea eliminar {{ DiscountNameToDelete }} de los descuentos
              disponibles?
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              @click.native="removeDiscount(DiscountTypeToDelete)"
              class="mt-4"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <vs-popup title="Actualización" :active.sync="popupDiscountUpdate">
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              v-validate="'required|alpha'"
              class="w-full"
              label="Titulo de descuento"
              v-model="updName"
            />
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <label for="type">Tipo de Descuento</label>
            <v-select v-model="updType" :options="discountTypes"></v-select>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col w-full">
            <vs-input
              v-validate="'required'"
              type="number"
              class="w-full"
              label="Valor del Descuento"
              v-model.number="updValue"
            />
          </div>
        </div>
        <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <span>Descripción del Proyecto:</span>
              <vs-textarea
                counter="250"
                placeholder=""
                v-model="updDescription"
                height="350"
              />
            </div>
          </div>
        <div class="center con-switch pb-4">
          Activo <vs-switch v-model="updActive" />
        </div>
        <vs-button
          color="success"
          @click="updateDiscount"
          icon-pack="feather"
          icon="icon-save"
          type="filled"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container w-full"
          >Actualizar descuento</vs-button
        >
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { discountApi, api } from "../services";
import vSelect from "vue-select";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      discountTypes: [],
      discounts: [],
      name: "",
      type: "",
      active: null,
      value: 0,
      popupDiscountDelete: false,
      DiscountTypeToDelete: "",
      DiscountNameToDelete: "",
      enableDiscount: true,
      project: null,
      itemsPerPage: 10,
      selected: null,
      popupDiscountUpdate: false,
      discountId: null,
      description : "",
      updName: "",
      updType: "",
      updActive: null,
      updValue: 0,
      updDescription: "",
    };
  },
  components: {
    "v-select": vSelect,
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.enableDiscount = this.project.discount_enable;
    const types = await discountApi.listTypes();
    this.discountTypes = types.map((d) => {
      return { value: d.id, label: d.name };
    });
    await this.fetchProjectDiscounts();
  },
  methods: {
    async fetchProjectDiscounts() {
      this.discounts = await discountApi.list(
        this.$store.state.project.currentProjectId
      );
    },
    async addProjectDiscount() {
      try {
        await discountApi.add(
          this.name,
          true,
          this.type.value,
          this.value,
          this.$store.state.project.currentProjectId,
          this.description
        );
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha creado un descuento!`,
          text: `Descuento ingresado correctamente.`,
          color: "success",
        });
        this.name = "";
        this.value = 0;
        this.type = "";
        this.active = null;
        this.description = "";
        await this.fetchProjectDiscounts();
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          time: 6000,
          title: `Error creando el descuento`,
          text: `No se ha podido crear el descuento`,
          color: "danger",
        });
      }
    },
    async removeDiscount(discountId) {
      try {
        await discountApi.remove(discountId);
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha eliminado el descueto!`,
          text: `Descuento eliminado correctamente.`,
          color: "success",
        });
        await this.fetchProjectDiscounts();
        this.DiscountTypeToDelete = "";
        this.DiscountNameToDelete = "";
        this.popupDiscountDelete = false;
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al eliminar el descuento`,
          text: `No se ha podido eliminar el descuento del proyecto`,
          color: "danger",
        });
      }
    },
    async popUpupdateDiscount(id) {
      this.popupDiscountUpdate = true;
      const discount = await discountApi.get(id);
      this.updName = discount.name;
      this.updValue = discount.value;
      this.updType = {
        label: discount.discount_type.name,
        value: discount.discount_type.id,
      };
      this.discountId = discount.id;
      this.updActive = discount.active;
      this.updDescription = discount.description;
    },
    async updateDiscount() {
      try {
        await discountApi.update(this.discountId, {
          name: this.updName,
          value: this.updValue,
          type: this.updType.value,
          active: this.updActive,
          description: this.updDescription
        });
        
        this.updName = "";
        this.updValue = 0;
        this.updType = null;
        this.updActive = false;
        this.updDescription = "";

        this.popupDiscountUpdate = false;

        await this.fetchProjectDiscounts();
        
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha actualizado el descuento!`,
          text: `Descuento actualizado correctamente.`,
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al actualizar descuentos generales`,
          text: `No se ha podido actualizar descuento del proyecto`,
          color: "danger",
        });
      }
    },
  },
  watch: {
    async enableDiscount(value) {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        await api.updProject(projectId, { discount_enable: value });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al activar descuentos generales`,
          text: `No se ha podido activar descuento del proyecto`,
          color: "danger",
        });
      }
    },
  },
};
</script>
