const formatNumber = number => {
  return parseFloat(number)
    .toFixed(2)
    .toString()
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const currencyFormatter = ({ value, currency }) => {
  if (!currency) currency = "$";
  return `${currency}. ` + formatNumber(value);
};

export default {
  currencyFormatter
};
