<template>
  <div>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">1</vs-chip>Datos generales de
        proyecto
      </div>
      <ProjectSettings />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">2</vs-chip>
        Preferencias de {{ $tc("message.downpayment", 1, user.countryCode) }}
      </div>
      <ProjectSeparation />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">3</vs-chip>Bancos y tasas de
        interés
      </div>
      <FinancingComponent />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">4</vs-chip>Descuentos generales
      </div>
      <DiscountsComponent />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">5</vs-chip>Productos adicionales y
        reformas
      </div>
      <ExtrasComponent />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">6</vs-chip>Preferencias de
        proyecto
      </div>
      <QuotePreferences />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">7</vs-chip>Descuentos financieros
      </div>
      <DiscountRates />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">8</vs-chip>Configuración de impuestos
      </div>
      <TaxSetting />
    </vs-collapse-item>
    <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
      <div slot="header">
        <vs-chip color="success" class="mr-4">9</vs-chip>Otras configuraciones
      </div>
      <OtherSettings />
    </vs-collapse-item>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FinancingComponent from "./FinancingSettings.vue";
import DiscountsComponent from "./DiscountSettings.vue";
import ExtrasComponent from "./ExtrasSettings.vue";
import QuotePreferences from "./QuotePreferences.vue";
import ProjectSettings from "./ProjectSettings.vue";
import DiscountRates from "./DiscountRates.vue";
import ProjectSeparation from "./ProjectSeparation.vue";
import TaxSetting from "./TaxSettings.vue";
import OtherSettings from "./OtherSettings.vue";

export default {
  data() {
    return {};
  },
  async mounted() {
    this.$store.state.project.breadcrumbs = [
      { pageName: 'Edición de proyectos', route: '/proyectos' },
      {
        pageName: 'Configuración de proyectos',
        route: `/configuraciones-de-proyecto`
      }
    ];
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  components: {
    FinancingComponent,
    ExtrasComponent,
    DiscountsComponent,
    QuotePreferences,
    ProjectSettings,
    DiscountRates,
    ProjectSeparation,
    TaxSetting,
    OtherSettings
  }
};
</script>

<style lang="scss">
  [dir=ltr] .vs-input--input.large.hasIcon,
  [dir=ltr] .vs-input--input.hasIcon {
    padding: 0.4rem 1rem 0.4rem 3rem !important;
    border-radius: 5px;
  }

  [dir] .vs-input--input.large {
    padding: 0.3rem 0.5rem  !important;
    border-radius: 5px;
  }

  [dir=ltr] .vs-input--icon.feather {
    padding: 0.0rem 0.5rem 0.0rem 0.2rem !important;
  }

</style>