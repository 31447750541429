<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
        >
            <h6 className="mb-4">
              Preferencias de
              {{ $tc("message.Downpayment", 2, user.countryCode) }}
            </h6>
            <div class="row mb-4">
              <div class="col-6">
                <vs-input
                  v-model.trim="name"
                  class="w-full"
                  label="Nombre"
                  name="downpayment_name"
                />
              </div>
              <div class="col-6">
                <vs-input
                  v-model.number="downpaymentRate"
                  type="number"
                  class="w-full"
                  name="downpayment_rate"
                  :label="
                    `Porcentaje (%) de ${$tc(
                      'message.downpayment',
                      2,
                      user.countryCode
                    )}`
                  "
                />
              </div>
            </div>
            <div class="row mb-4">             
              <div class="col-3">
                Tipo de {{ $tc("message.reservation", 2, user.countryCode) }}
              </div>
              <div class="col-3">
                <v-radio-group v-model="isReservationPercent" 
                row
                hide-details style="margin-top: 0px;">
                    <v-radio
                      name="amount-percent"
                      :value="false"
                      label="Monto"
                      ></v-radio>
                      <v-radio
                      name="is_reservation_percent"
                      :value="true"
                      label="Porcentaje"></v-radio>
                </v-radio-group>
             </div>
            </div>
            <!-- dfdfd -->
            <div class="row mb-4">
              <div class="col-6">
                <vs-input
                  v-model.number="reservation"
                  type="number"
                  class="w-full"
                  :label="`Monto de ${$tc('message.reservation', 2, user.countryCode)}`"
                  name="downpayment_amount"
                />
              </div>
              <div class="col-6">
                <vs-input
                  v-model.number="maxDownpaymentInstallments"
                  type="number"
                  class="w-full"
                  label="Meses a pagar"
                  name="downpayment_monthly"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <vs-input
                  v-model.number="pcvRate"
                  type="number"
                  class="w-full"
                  label="Porcantaje (%) a pagar en PCV"
                  name="downpayment_pcv"
                />
              </div>
              <div class="col-6">
                <vs-input
                  v-model.number="pcvDays"
                  type="number"
                  class="w-full"
                  label="Día para pago de PCV"
                  name="downpayment_pcv_days"
                />
              </div>
            </div>
            <div class="row mb-4">
              <vs-button
                icon-pack="feather"
                color="success"
                icon="icon-save"
                class="mt-8 m-2"
                @click="newDownpayment"
                :disabled="fieldsReady"
                >Guardar {{ name }}</vs-button
              >
            </div>
      
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
        >
          <div class="p-3">
            <h6>Actuales</h6>
            <vs-divider></vs-divider>
            <vs-table
              single
              pagination
              :max-items="10"
              :data="downpaymentTypes"
              noDataText="🤦‍♂️ No hay tasas de interés creadas..."
            >
              <template slot="thead">
                <vs-th>Nombre</vs-th>
                <vs-th
                  >Porcentaje (%) de
                  {{ $tc("message.downpayment", 2, user.countryCode) }}</vs-th
                >
                <vs-th
                  >Monto de
                  {{ $tc("message.reservation", 2, user.countryCode) }}</vs-th
                >
                <vs-th
                  >Tipo de
                  {{ $tc("message.reservation", 2, user.countryCode) }}</vs-th
                >
                <vs-th>Meses a pagar</vs-th>
                <vs-th>Porcentaje (%) a pagar en PCV</vs-th>
                <vs-th>Día para pago de PCV</vs-th>
                <vs-th>Estado</vs-th>
                <vs-th></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                  <vs-td :data="data[index].name">
                    {{ data[index].name }}
                  </vs-td>
                  <vs-td :data="data[index].downpayment_rate">
                    {{ data[index].downpayment_rate }} %
                  </vs-td>
                  <vs-td :data="data[index].reservation">
                    {{ data[index].is_reservation_percent ? `${data[index].reservation}%` : 
                    setCurrency(data[index].reservation) }}
                  </vs-td>
                  <vs-td :data="data[index].max_payment_installments">
                    {{ data[index].is_reservation_percent ? 'Porcentaje' : 'Monto' }}
                  </vs-td>
                  <vs-td :data="data[index].max_payment_installments">
                    {{ data[index].max_payment_installments }}
                  </vs-td>
                  <vs-td :data="data[index].pcv_rate">
                    {{ data[index].pcv_rate }}
                  </vs-td>
                  <vs-td :data="data[index].pcv_payment_day">
                    {{ data[index].pcv_payment_day }}
                  </vs-td>
                  <vs-td :data="data[index].active">
                    <vs-chip>{{
                        data[index].active ? "Activo" : "Inactivo"
                      }}</vs-chip>
                  </vs-td>
                  <vs-td>
                    <vs-row>
                      <vs-button
                        color="primary"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="p-1"
                        @click="editDownpaymentType(data[index])"
                      ></vs-button>
                      <vs-button
                        color="danger"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-trash"
                        class="p-1"
                        @click="deleteDownpaymentType(data[index])"
                      ></vs-button>
                    </vs-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <!-- popup -->
    <vs-popup title="Edición" :active.sync="openEditModal">
      <PopupDownpaymentType
        :onUpdate="onUpdate"
        :downpaymentType="downpaymentTypeProps"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import currencies from "../../../helpers/currencies";
import PopupDownpaymentType from "./PopupDownpaymentType.vue";
import { fa } from "vuejs-datepicker/src/locale";

export default {
  components: {
    PopupDownpaymentType
  },
  data() {
    return {
      project: {},
      minDownpaymentPercent: 0,
      name: "",
      downpaymentRate: 0,
      reservation: 0,
      pcvRate: 0,
      pcvDays: 0,
      maxDownpaymentInstallments: 0,
      downpaymentTypes: [],
      openEditModal: false,
      downpaymentTypeProps: null,
      isReservationPercent: false
    };
  },
  async mounted() {
    await this.getProject();
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.name ||
        !this.downpaymentRate ||
        !this.maxDownpaymentInstallments ||
        !this.reservation
      )
        return true;

      return false;
    }
  },
  methods: {
    async getProject() {
      const projectId = this.$store.state.project.currentProjectId;
      this.project = await api.get(projectId);
      this.downpaymentTypes = await api.getDownpaymentTypes(projectId);
    },
    setCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value,
        currency: currencySymbol
      });
    },
    async newDownpayment() {
      try {
        const downpayment = {
          project_id: this.project.id,
          name: this.name.toUpperCase(),
          downpayment_rate: this.downpaymentRate,
          reservation: this.reservation,
          pcv_rate: !this.pcvRate ? 0 : this.pcvRate,
          pcv_payment_day: !this.pcvDays ? 0 : this.pcvDays,
          max_payment_installments: this.maxDownpaymentInstallments,
          is_reservation_percent: this.isReservationPercent
        };

        await api.addDownpaymentType(downpayment);

        this.downpaymentTypes = await api.getDownpaymentTypes(this.project.id);
        this.name = "";
        this.downpaymentRate = 0;
        this.pcvRate = 0;
        this.pcvDays = 0;
        this.reservation = 0;
        this.maxDownpaymentInstallments = 0;
        this.isReservationPercent = false;

        this.$vs.notify({
          title: `Nuevo registro.`,
          text: `Dato registrado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");

        this.$vs.notify({
          title: `📝 Error.`,
          text: `No se ha podido registrar dato en este momento.`,
          color: "danger"
        });
      }
    },
    async deleteDownpaymentType(downpaymentType) {
      try {
        const { id } = downpaymentType;

        if (this.downpaymentTypes.length === 1) {
          this.$vs.notify({
            title: `Error al eliminar ${downpaymentType.name}`,
            text: `Es necesario al menos un registro para la configuración.`,
            color: "danger"
          });

          return;
        }

        await api.deleteDownpaymentType(id);
        this.downpaymentTypes = await api.getDownpaymentTypes(this.project.id);

        this.$vs.notify({
          title: `Registro eliminado.`,
          text: `Se ha eliminado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");

        this.$vs.notify({
          title: `Error.`,
          text: `No se ha podido eliminar dato en este momento.`,
          color: "danger"
        });
      }
    },
    editDownpaymentType(downpaymentType) {
      this.openEditModal = true;
      this.downpaymentTypeProps = downpaymentType;
    },
    async onUpdate() {
      try {
        this.downpaymentTypes = await api.getDownpaymentTypes(this.project.id);
        this.openEditModal = false;

        this.$vs.notify({
          title: `Registro actualizado.`,
          text: `Se ha actualizado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");

        this.$vs.notify({
          title: `Error.`,
          text: `No se ha podido eliminar dato en este momento.`,
          color: "danger"
        });
      }
    }
  }
};
</script>

<style lang="scss">
.actualImageHeader {
  width: 60%;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}
.vSelector {
  width: 100%;
}
.vuesax-app-is-ltr .vs-input--input.hasIcon + .vs-input--placeholder {
  color: #000 !important;
}
.removeImgBtn {
  position: absolute;
  margin-left: 41.66666%;
}

[dir] .vs-con-input .vs-inputx {
  border-radius: 5px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .removeImgBtn {
    position: absolute;
    margin-left: 70.777777%;
  }
}
.v-label {
    font-size: 12px;
}
</style>
