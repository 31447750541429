import Jimp from "jimp";

const resizeImage = async (
  blob,
  width,
  height,
  quality,
  mimeType = Jimp.MIME_JPEG
) => {
  const bufferFile = await Jimp.read(blob);
  const resizeBuffer = await bufferFile
    .resize(width, height)
    .quality(quality)
    .background(0xffffffff)
    .getBufferAsync(mimeType);
  const newFile = new File([resizeBuffer], null, { type: mimeType });
  return newFile;
};

export { resizeImage };