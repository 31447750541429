<template>
  <div>
    <div>
      <vx-card>
        <vs-row vs-w="12">
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="p-6">
              <h6>Descuentos Financieros:</h6>
              <vs-divider></vs-divider>
              <div class="center con-switch">
                Permitir descuentos financieros
                <vs-switch v-model="enableDiscount" />
              </div>
              <div class="mt-4">
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required|alpha'"
                      class="w-full"
                      type="number"
                      label="% tasa de descuento"
                      v-model="discountRate"
                      :disabled="!enableDiscount"
                      name="discount_rate"
                    />
                  </div>
                </div>
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <vs-input
                      v-validate="'required'"
                      type="number"
                      class="w-full"
                      label="% tasa de recargo"
                      v-model="discountExtraFee"
                      :disabled="!enableDiscount"
                      name="discount_extra_fee"
                    />
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <vs-button
                      color="success"
                      icon-pack="feather"
                      icon="icon-upload"
                      class="mr-3 mb-2"
                      @click="storeDiscounts"
                      :disabled="!enableDiscount"
                      >Guardar</vs-button
                    >
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { api } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      discountRate: 0,
      discountExtraFee: 0,
      openModal: false,
      project: null,
      enableDiscount: true
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.enableDiscount = this.project.financial_discount_enable;
    this.discountExtraFee = this.project.discount_extra_fee
      ? this.project.discount_extra_fee
      : 0;
    this.discountRate = this.project.discount_rate
      ? this.project.discount_rate
      : 0;
  },
  methods: {
    async storeDiscounts() {
      try {
        const discountRate = this.discountRate
          ? parseFloat(this.discountRate)
          : 0;
        const discountExtraFee = this.discountExtraFee
          ? parseFloat(this.discountExtraFee)
          : 0;
        const projectId = this.$store.state.project.currentProjectId;
        await api.updProject(projectId, {
          discount_rate: discountRate,
          discount_extra_fee: discountExtraFee
        });
        this.$vs.notify({
          time: 6000,
          title: `¡Se ha creado descuento financiero!`,
          text: `Descuento ingresado correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          time: 6000,
          title: `Error creando el descuento financiero`,
          text: `No se ha podido crear el descuento`,
          color: "danger"
        });
      }
    }
  },
  watch: {
    async enableDiscount(value) {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        await api.updProject(projectId, { financial_discount_enable: value });
      } catch (e) {
        sentryCaptureException(e, "project");
        this.$vs.notify({
          title: `Error al activar descuentos generales`,
          text: `No se ha podido activar descuento del proyecto`,
          color: "danger"
        });
      }
    }
  }
};
</script>
